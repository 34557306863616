














import { Observer } from 'mobx-vue'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { PostStore } from '@/stores/post-store'
import MasonryWall from '@yeger/vue2-masonry-wall'
import { promiseHelper } from '@/helper/promise-helper'
import { computed } from 'mobx'

@Observer
@Component({
  components: {
    MasonryWall,
    'post-grid-item': () => import('./post-grid-item.vue'),
  },
})
export default class PostGrid extends Vue {
  @Prop({}) postStores?: PostStore[]
  @Prop({ default: 335 }) columnMinWidth?: number
  @Prop({ default: false }) enableRedirectPostDetail
  @Ref('mwRef') mwRef

  @Watch('postStores', { immediate: true }) async itemsChanged() {
    await promiseHelper.delay(1000)
    if (this.mwRef) {
      this.mwRef.redraw(true)
    }
  }

  get validPostStores() {
    return this.postStores?.filter((item) => !item.isRecruitment)
  }
}
